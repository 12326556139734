import { useState } from 'react';
import Scroll from 'react-scroll';
import HeadGirl from '../../assets/HeadGirl1.png';
import Logo from '../../assets/Logo1.png';
import StarLine from '../../assets/HeadStarLine.svg';
import './styles.scss';
import useNavBar from '../../hooks/useNavBar';

const Element = Scroll.Element;
const scroller = Scroll.scroller;

const scroll = (element: string, offset: number) => {
  scroller.scrollTo(element, {
    duration: 50,
    delay: 100,
    smooth: true,
    offset: offset, // Scrolls to element + 50 pixels down the page
  });
};

const Head = ({ windowWidth }: { windowWidth: number }) => {
  const [showMobileNavContent, setShowMobileNavContent] = useState(false);

  const toggleMobileNav = () => {
    setShowMobileNavContent(!showMobileNavContent);
  };

  const [isFixed] = useNavBar();
  return (
    <Element name='head'>
      <div className='head-section'>
        <div
          className='head-bar-wrapper'
          style={{
            position: isFixed ? 'fixed' : 'absolute',
            backgroundColor: isFixed ? 'rgba(255,235,208,1)' : 'rgba(255,244,229,1)',
            height: isFixed ? '77px' : 'auto',
            boxShadow: isFixed ? '0 10px 10px rgba(0, 0, 0, 0.1)' : '',
          }}
        >
          {windowWidth > 767 ? (
            <div className='head-bar'>
              <div className='logo-wrapper'>
                <img src={Logo} alt='Logo' className='logo' />
              </div>
              <div className='head-navbar'>
                <span className='nav-tab' onClick={() => scroll('aboutMe', 80)}>
                  About Me
                </span>
                <span className='nav-tab' onClick={() => scroll('skills', -140)}>
                  Skills
                </span>
                <span className='nav-tab' onClick={() => scroll('experience', -10)}>
                  My Story
                </span>
              </div>
              <div className='resume-button-border'>
                <a
                  href='https://drive.google.com/file/d/1wCFzv3njcEJa64DUfYJFd-gykfD8OCBg/view?usp=sharing'
                  target='_blank'
                  rel='noreferrer'
                  className='resume-button'
                >
                  Resume
                </a>
              </div>
            </div>
          ) : (
            <div className='head-bar'>
              <div className='logo-wrapper'>
                <img src={Logo} alt='Logo' className='logo' />
              </div>
              <div className={`menu-icon ${showMobileNavContent ? 'active' : ''}`} onClick={toggleMobileNav}>
                <div></div>
              </div>
            </div>
          )}
          <div className={`mobile-nav ${showMobileNavContent ? 'active' : ''}`}>
            <div className='mobile-nav-content-wrapper' style={{ marginTop: isFixed ? '80px' : '100px' }}>
              <div style={{ height: showMobileNavContent ? 15 : 0, width: '100%' }}></div>
              <div
                className='mobile-nav-content'
                onClick={() => {
                  scroll('aboutMe', -30);
                  toggleMobileNav();
                }}
              >
                About Me
              </div>
              <div
                className='mobile-nav-content'
                onClick={() => {
                  scroll('skills', -70);
                  toggleMobileNav();
                }}
              >
                Skills
              </div>
              <div
                className='mobile-nav-content'
                onClick={() => {
                  scroll('experience', -50);
                  toggleMobileNav();
                }}
              >
                My Story
              </div>
              <div className='mobile-nav-resume-button-wrapper'>
                <div className='mobile-nav-resume-button-border'>
                  <a
                    href='https://drive.google.com/file/d/1wCFzv3njcEJa64DUfYJFd-gykfD8OCBg/view?usp=sharing'
                    target='_blank'
                    rel='noreferrer'
                    className='mobile-nav-resume-button'
                  >
                    Resume
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {windowWidth > 767 ? (
          <div className='head-content'>
            <h1 className='font-flower head-text-1'>Celia Wong</h1>
            <h2 className='font-flower head-text-2'>Live best of my life</h2>
            <h3 className='font-flower head-text-3'>Cat lovers, sweet food enthusiast</h3>
            <h3 className='font-flower head-text-4'>
              and{' '}
              <span className='font-flower' style={{ textDecoration: 'line-through' }}>
                data analyst
              </span>{' '}
              web developer
            </h3>
            <div className='more-button-border' style={{ margin: 0 }}>
              <a className='more-button' onClick={() => scroll('aboutMe', 50)}>
                More
              </a>
            </div>
          </div>
        ) : (
          <div className='head-content'>
            <h1 className='font-flower head-text-1'>Celia Wong</h1>
            <h2 className='font-flower head-text-2'>Live best of my life</h2>
            <div className='more-button-border' style={{ margin: 0 }}>
              <a className='more-button' onClick={() => scroll('aboutMe', -10)}>
                More
              </a>
            </div>
          </div>
        )}
        <div className='head-girl-img-wrapper'>
          <img src={HeadGirl} alt='Girl Img' className='head-girl-img' />
        </div>
        <div className='head-girl-line' />
        <div className='head-star-img-wrapper'>
          <img src={StarLine} alt='Star Img' className='head-star-img' />
        </div>
      </div>
    </Element>
  );
};

export default Head;
