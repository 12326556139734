import Scroll from 'react-scroll';
import BackgroundTop from '../../assets/AboutMeBackgroundTop.png';
import BackgroundBottom from '../../assets/AboutMeBackgroundBottom.png';
import BackgroundBottom2 from '../../assets/AboutMeBackgroundBottom2.png';
import AboutMeGirl from '../../assets/AboutMeGirl.png';
import AboutMeBalls from '../../assets/AboutMeBalls.png';
import './styles.scss';

const Element = Scroll.Element;

interface props {
  windowWidth: number;
}

const AboutMe = ({ windowWidth }: props) => {
  return (
    <Element name='aboutMe'>
      <img src={BackgroundTop} className='about-me-background-top' alt='Top Img' />
      <div className='about-me-section-wrapper'>
        <div className='about-me-section'>
          <div className='about-me-girl-img-wrapper'>
            <img src={AboutMeGirl} alt='Girl Img' className='about-me-girl-img' />
          </div>
          <div className='about-me-balls-img-wrapper'>
            <img src={AboutMeBalls} alt='Balls Img' className='about-me-balls-img' />
          </div>
          <div className='about-me-content'>
            <h2 className='about-me-title'>About Me</h2>
            <p className='about-me-text'>
              I am a Software Engineer with Management Science background with 2+ years experience in data analytics and
              2+ years in web and app development. I spent a year to learn the development skills I need in&nbsp;
              <a href='https://www.udemy.com' target='_blank' rel='noreferrer'>
                Udemy
              </a>
              .
            </p>
            <div className='about-me-button-wrapper'>
              <div className='about-me-button-border'>
                <a
                  href='https://www.linkedin.com/in/celia-wong-34844090/'
                  target='_blank'
                  className='about-me-button'
                  rel='noreferrer'
                >
                  LinkedIn
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {windowWidth > 767 ? (
        <img src={BackgroundBottom} className='about-me-background-bottom' alt='Bottom Img' />
      ) : (
        <img src={BackgroundBottom2} className='about-me-background-bottom' alt='Bottom Img' />
      )}
    </Element>
  );
};

export default AboutMe;
