import { useState, useEffect } from "react";

// React hook to reflect the status of the navbar (absolute/fixed)
const useNavBar = () => {
  const [isFixed, setFixed] = useState<boolean>(false);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setFixed(true);
    } else {
      setFixed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return [isFixed];
};

export default useNavBar;
