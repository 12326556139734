import React from "react";
import Head from "./homePage/Head/";
import AboutMe from "./homePage/AboutMe/";
import Skills from "./homePage/Skills/";
import Experience from "./homePage/Experience/";
import Footer from "./homePage/Footer/";

interface props {}
interface state {
  windowWidth: number;
  isMobile: boolean;
}

class App extends React.Component<props, state> {
  constructor(props: props) {
    super(props);

    this.state = {
      // showBars: true,
      windowWidth: 0,
      isMobile: false,
    };
  }

  componentDidMount() {
    this.updateWidth();
    window.addEventListener("resize", this.updateWidth);
  }

  updateWidth = () => {
    this.setState({
      windowWidth: window.innerWidth,
    });

    if (window.innerWidth <= 576) {
      this.setState({ isMobile: true });
    }
  };

  render() {
    return (
      <div id="app">
        <Head
          windowWidth={this.state.windowWidth}
          // isMobile={this.state.isMobile}
        />
        <AboutMe windowWidth={this.state.windowWidth} />
        <Skills windowWidth={this.state.windowWidth} />
        <Experience windowWidth={this.state.windowWidth} />
        <Footer windowWidth={this.state.windowWidth} />
      </div>
    );
  }
}

export default App;
