import Scroll from 'react-scroll';

import ExperienceItem from '../../components/atoms/ExperienceItem';
import ExperienceGraduatedImg from '../../assets/ExperienceGraduatedImg.png';
import ExperienceWorkingGirls from '../../assets/ExperienceWorkingGirls.png';
import ExperienceWorkingBag from '../../assets/ExperienceWorkingBag.png';
import ExperienceStrugglingGirl from '../../assets/ExperienceStrugglingGirl.png';
import ExperienceEastWest from '../../assets/ExperienceEastWest.png';
import ExperienceMakeDecision from '../../assets/ExperienceMakeDecision.png';
import ExperienceNeverGiveUp from '../../assets/ExperienceNeverGiveUp.png';
import ExperienceWebGirl from '../../assets/ExperienceWebGirl.png';
import ExperienceWorkingGirl from '../../assets/ExperienceWorkingGirl.png';
import ExperienceLine from '../../assets/ExperienceLine.png';
import ExperienceLine2 from '../../assets/ExperienceLine2.png';
import ExperienceStars from '../../assets/ExperienceStars.png';
import ExperienceStars2 from '../../assets/ExperienceStars2.png';
import ExperienceStars3 from '../../assets/ExperienceStars3.png';
import './styles.scss';

const Element = Scroll.Element;

interface props {
  windowWidth: number;
}

const Experience = ({ windowWidth }: props) => {
  const { cityU, dataAnalystJLL, struggle, makeDecision, webDevStudy, developerJLL } = data;

  return (
    <Element name='experience'>
      {windowWidth > 854 ? (
        <div className='experience-section-wrapper'>
          <div className='experience-head'>
            <h3 className='experience-head-title'>My story</h3>
            <h2 className='experience-head-subtitle'>from data analyst</h2>
            <h2 className='experience-head-subtitle'>
              to <span style={{ color: '#91CF42' }}>frontend developer</span>
            </h2>
          </div>
          <div className='experience-section'>
            <div className='experience-content'>
              <div className='experience-content-left'>
                <ExperienceItem
                  date={cityU.date}
                  content={cityU.content}
                  backgroundColor={cityU.backgroundColor}
                  paddingTop='experience-graduated-item-padding'
                  image={cityU.image}
                  imageHeight='experience-graduated-girl-img'
                  imageWrapper='experience-graduated-girl-img-wrapper'
                  imageAlt={cityU.imageAlt}
                />
                <div className='space-between-items' />
                <ExperienceItem
                  date={struggle.date}
                  content={struggle.content}
                  backgroundColor={struggle.backgroundColor}
                  paddingTop='experience-struggle-item-padding'
                  image={struggle.image}
                  imageHeight='experience-struggling-girl-img'
                  imageWrapper='experience-struggling-girl-img-wrapper'
                  imageAlt={struggle.imageAlt}
                  imageAddon={struggle.imageAddon}
                  imageAddonHeight='experience-struggling-img'
                  imageAddonWrapper='experience-struggling-img-wrapper'
                  imageAddonAlt={struggle.imageAddonAlt}
                />
                <div className='space-between-items' />
                <ExperienceItem
                  date={webDevStudy.date}
                  content={webDevStudy.content}
                  backgroundColor={webDevStudy.backgroundColor}
                  paddingTop='experience-web-item-padding'
                  image={webDevStudy.image}
                  imageHeight='experience-web-study-img'
                  imageWrapper='experience-web-study-img-wrapper'
                  imageAlt={webDevStudy.imageAlt}
                />
              </div>
              <div className='timeline'>
                <div className='timeline-point start-point' />
                <div className='timeline-point' />
                <div className='timeline-point' />
                <div className='timeline-point' />
                <div className='timeline-point end-point' />
              </div>
              <div className='experience-content-right'>
                <div className='space-between-items' />
                <ExperienceItem
                  date={dataAnalystJLL.date}
                  content={dataAnalystJLL.content}
                  backgroundColor={dataAnalystJLL.backgroundColor}
                  paddingTop='experience-work-item-padding'
                  image={dataAnalystJLL.image}
                  imageHeight='experience-working-girls-img'
                  imageWrapper='experience-working-girls-img-wrapper'
                  imageAlt={dataAnalystJLL.imageAlt}
                  imageAddon={dataAnalystJLL.imageAddon}
                  imageAddonHeight='experience-working-bag-img'
                  imageAddonWrapper='experience-working-bag-img-wrapper'
                  imageAddonAlt={dataAnalystJLL.imageAddonAlt}
                />
                <div className='space-between-items' />
                <ExperienceItem
                  date={makeDecision.date}
                  content={makeDecision.content}
                  contentAddon={makeDecision.contentAddon}
                  contentAddon2={makeDecision.contentAddon2}
                  contentAddon3={makeDecision.contentAddon3}
                  backgroundColor={makeDecision.backgroundColor}
                  paddingTop='experience-make-decision-item-padding'
                  image={makeDecision.image}
                  imageHeight='experience-make-decision-img'
                  imageWrapper='experience-make-decision-img-wrapper'
                  imageAlt={makeDecision.imageAlt}
                  imageAddon={makeDecision.imageAddon}
                  imageAddonHeight='experience-never-give-up-img'
                  imageAddonWrapper='experience-never-give-up-img-wrapper'
                  imageAddonAlt={makeDecision.imageAddonAlt}
                />
                <div className='space-between-items' />
                <ExperienceItem
                  date={developerJLL.date}
                  content={developerJLL.content}
                  backgroundColor={developerJLL.backgroundColor}
                  paddingTop='experience-web-developer-item-padding'
                  image={developerJLL.image}
                  imageHeight='experience-web-developer-img'
                  imageWrapper='experience-web-developer-img-wrapper'
                  imageAlt={developerJLL.imageAlt}
                />
              </div>
            </div>
          </div>
          <div className='experience-line-img-wrapper'>
            <img src={ExperienceLine} className='experience-line-img' alt='Graduated Line Img' />
          </div>
          <div className='experience-line2-img-wrapper'>
            <img src={ExperienceLine2} className='experience-line2-img' alt='Graduated Line Img' />
          </div>
          <div className='experience-stars-img-wrapper'>
            <img src={ExperienceStars} className='experience-stars-img' alt='Stars Img' />
          </div>
          <div className='experience-stars2-img-wrapper'>
            <img src={ExperienceStars2} className='experience-stars2-img' alt='Stars Img' />
          </div>
          <div className='experience-stars3-img-wrapper'>
            <img src={ExperienceStars3} className='experience-stars3-img' alt='Stars Img' />
          </div>
        </div>
      ) : (
        <div className='experience-section-wrapper'>
          <div className='experience-head-wrapper'>
            <div className='experience-head'>
              <h3 className='experience-head-title'>My experience</h3>
              <h2 className='experience-head-subtitle'>from data analyst</h2>
              <h2 className='experience-head-subtitle'>
                to <span style={{ color: '#91CF42' }}>frontend developer</span>
              </h2>
            </div>
          </div>
          <div className='experience-section'>
            <div className='experience-content'>
              <ExperienceItem
                date={cityU.date}
                content={cityU.content}
                backgroundColor={cityU.backgroundColor}
                paddingTop='experience-graduated-item-padding'
                image={cityU.image}
                imageHeight='experience-graduated-girl-img'
                imageWrapper='experience-graduated-girl-img-wrapper'
                imageAlt={cityU.imageAlt}
              />
              <div className='space-between-items' />
              <ExperienceItem
                date={dataAnalystJLL.date}
                content={dataAnalystJLL.content}
                backgroundColor={dataAnalystJLL.backgroundColor}
                paddingTop='experience-work-item-padding'
                image={dataAnalystJLL.image}
                imageHeight='experience-working-girls-img'
                imageWrapper='experience-working-girls-img-wrapper'
                imageAlt={dataAnalystJLL.imageAlt}
                imageAddon={dataAnalystJLL.imageAddon}
                imageAddonHeight='experience-working-bag-img'
                imageAddonWrapper='experience-working-bag-img-wrapper'
                imageAddonAlt={dataAnalystJLL.imageAddonAlt}
              />
              <div className='space-between-items' />
              <ExperienceItem
                date={struggle.date}
                content={struggle.content}
                backgroundColor={struggle.backgroundColor}
                paddingTop='experience-struggle-item-padding'
                image={struggle.image}
                imageHeight='experience-struggling-girl-img'
                imageWrapper='experience-struggling-girl-img-wrapper'
                imageAlt={struggle.imageAlt}
                imageAddon={struggle.imageAddon}
                imageAddonHeight='experience-struggling-img'
                imageAddonWrapper='experience-struggling-img-wrapper'
                imageAddonAlt={struggle.imageAddonAlt}
              />
              <div className='space-between-items' />
              <ExperienceItem
                date={makeDecision.date}
                content={makeDecision.content}
                contentAddon={makeDecision.contentAddon}
                contentAddon2={makeDecision.contentAddon2}
                contentAddon3={makeDecision.contentAddon3}
                backgroundColor={makeDecision.backgroundColor}
                paddingTop='experience-make-decision-item-padding'
                image={makeDecision.image}
                imageHeight='experience-make-decision-img'
                imageWrapper='experience-make-decision-img-wrapper'
                imageAlt={makeDecision.imageAlt}
                imageAddon={makeDecision.imageAddon}
                imageAddonHeight='experience-never-give-up-img'
                imageAddonWrapper='experience-never-give-up-img-wrapper'
                imageAddonAlt={makeDecision.imageAddonAlt}
              />
              <div className='space-between-items' />
              <ExperienceItem
                date={webDevStudy.date}
                content={webDevStudy.content}
                backgroundColor={webDevStudy.backgroundColor}
                paddingTop='experience-web-item-padding'
                image={webDevStudy.image}
                imageHeight='experience-web-study-img'
                imageWrapper='experience-web-study-img-wrapper'
                imageAlt={webDevStudy.imageAlt}
              />
              <div className='space-between-items' />
              <ExperienceItem
                date={developerJLL.date}
                content={developerJLL.content}
                backgroundColor={developerJLL.backgroundColor}
                paddingTop='experience-web-developer-item-padding'
                image={developerJLL.image}
                imageHeight='experience-web-developer-img'
                imageWrapper='experience-web-developer-img-wrapper'
                imageAlt={developerJLL.imageAlt}
              />
            </div>
          </div>
          <div className='experience-line-img-wrapper'>
            <img src={ExperienceLine} className='experience-line-img' alt='Graduated Line Img' />
          </div>
          <div className='experience-stars2-img-wrapper'>
            <img src={ExperienceStars2} className='experience-stars2-img' alt='Stars Img' />
          </div>
          <div className='experience-stars3-img-wrapper'>
            <img src={ExperienceStars3} className='experience-stars3-img' alt='Stars Img' />
          </div>
        </div>
      )}
    </Element>
  );
};

const data = {
  cityU: {
    date: 'Summer 2017',
    content:
      'Graduated with a Bachelor degree in Management Science, I’m well trained with a combination of modern business concepts, mathematical modeling, statistics and numerical algorithms, as well as hands on experience on programming with SAS.',
    backgroundColor: '#FFE7BA',
    image: ExperienceGraduatedImg,
    imageAlt: 'Graduated Girl Img',
  },
  dataAnalystJLL: {
    date: '2017 - 2019',
    content:
      "Worked as a data analyst in JLL (real estate industry), I’m responsible for performing data visualization on large scaled datasets using Tableau and PowerPoint/Excel VBA. Also, enhance business team's productivity by automating their work flow with automated scripts written by SQL, Visual Basic and PowerShell. Moreover, perform web scrapping with Python.",
    backgroundColor: '#EEE3D5',
    image: ExperienceWorkingGirls,
    imageAlt: 'Working Girls Img',
    imageAddon: ExperienceWorkingBag,
    imageAddonAlt: 'Working Bag Img',
  },
  struggle: {
    date: 'Winter 2019',
    content:
      'Worked for almost three years, things started getting boring as the routine is almost the same for every dataset. There were also lots of data entry work that couldn’t be automated from time to time. It seems that I am unavoidably doing repetitive work everyday, killing my enthusiasm of work...',
    backgroundColor: '#B4A17E',
    image: ExperienceStrugglingGirl,
    imageAlt: 'Struggling Girls Img',
    imageAddon: ExperienceEastWest,
    imageAddonAlt: 'Struggling Img',
  },
  makeDecision: {
    date: '31 Jan 2020!',
    content:
      'After exploring and rethinking about my career. I decided to work on a job that could give me a larger sense of achievement, a job that has a larger extend of diversification, a job that allows me to build products that help people to solve different challenges and most importantly less repetitive.',
    contentAddon: 'As a result, I decided to become a self-taught ',
    contentAddon2: 'Web/App software developer',
    contentAddon3: '!',
    backgroundColor: '#F3BF5A',
    image: ExperienceMakeDecision,
    imageAlt: 'Make Decision Img',
    imageAddon: ExperienceNeverGiveUp,
    imageAddonAlt: 'Never Give Up Img',
  },
  webDevStudy: {
    date: 'Feb - July 2020',
    content:
      'Filled with enthusiasm, I have built up solid fundamentals of web and app development by attending some great online courses in Udemy. With the knowledge I learnt, I’m able to created this nicely built website and a number of projects, check out my resume for more!',
    backgroundColor: '#7F886B',
    image: ExperienceWebGirl,
    imageAlt: 'Web Study Img',
  },
  developerJLL: {
    date: 'Nov 2020 - Nov 2021',
    content:
      "Worked as a software engineer in JLL, I'm responsible for developing web applications for property valuation with ReactJS (on Typescript). In the very beginning, I used Firebase realtime database since it speeded up the development. At the later stage, I migrated the project from Firebase to MySQL using Java Spring boot for a more secure database environment.",
    backgroundColor: '#EEE3D5',
    image: ExperienceWorkingGirl,
    imageAlt: 'Working Girl Img',
  },
};
export default Experience;
