import './styles.scss';

interface props {
  date: string;
  content: string;
  contentAddon?: string;
  contentAddon2?: string;
  contentAddon3?: string;
  backgroundColor: string;
  paddingTop: string;
  image?: string;
  imageHeight?: string;
  imageWrapper?: string;
  imageAlt?: string;
  imageAddon?: string;
  imageAddonHeight?: string;
  imageAddonWrapper?: string;
  imageAddonAlt?: string;
}

const ExperienceItem = ({
  date,
  content,
  contentAddon,
  contentAddon2,
  contentAddon3,
  backgroundColor,
  paddingTop,
  image,
  imageHeight,
  imageWrapper,
  imageAlt,
  imageAddon,
  imageAddonHeight,
  imageAddonWrapper,
  imageAddonAlt,
}: props) => {
  return (
    <div
      className={`experience-item-section ${paddingTop}`}
      style={{
        backgroundColor: `${backgroundColor}`,
      }}
    >
      <div className='experience-item-wrapper'>
        <div className='experience-item-date'>{date}</div>
        <div className='experience-item-content'>{content}</div>
        <div className='experience-item-content-addon'>
          {contentAddon}
          <span className='text-deco'>{contentAddon2}</span>
          <span>{contentAddon3}</span>
        </div>
      </div>
      <div className={imageWrapper}>
        <img src={image} className={imageHeight} alt={imageAlt} />
      </div>
      <div className={imageAddonWrapper}>
        <img src={imageAddon} className={imageAddonHeight} alt={imageAddonAlt} />
      </div>
    </div>
  );
};

export default ExperienceItem;
