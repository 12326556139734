import { useState } from 'react';
import Scroll from 'react-scroll';
import FooterBackgroundTop from '../../assets/FooterBackgroundTop.png';
import FooterBackgroundTop2 from '../../assets/FooterBackgroundTop2.png';
import LinkedIn from '../../assets/LinkedIn.png';
import Email from '../../assets/Email.png';
import Whatsapp from '../../assets/Whatsapp.png';
import AirBallon from '../../assets/AirBallon.png';
import BackgroundBottom from '../../assets/Mountains.png';
import Cloud1 from '../../assets/Cloud1.png';
import Cloud2 from '../../assets/Cloud2.png';
import Cloud3 from '../../assets/Cloud3.png';
import Cloud4 from '../../assets/Cloud4.png';
import './styles.scss';
import { Tooltip } from 'reactstrap';

const Element = Scroll.Element;
const scroller = Scroll.animateScroll;

const scroll = () => {
  scroller.scrollToTop({
    duration: 50,
    delay: 100,
    smooth: true,
  });
};

interface props {
  windowWidth: number;
}

const Footer = ({ windowWidth }: props) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <Element name='footer'>
      {windowWidth > 854 ? (
        <img src={FooterBackgroundTop} className='footer-background-top' alt='Background Top' />
      ) : (
        <img src={FooterBackgroundTop2} className='footer-background-top' alt='Background Top' />
      )}
      <div className='footer-section-wrapper'>
        <div className='footer-section'>
          <div className='contact-section'>
            <div className='contact-wrapper'>
              <a href='https://www.linkedin.com/in/celia-wong-34844090/' target='_blank' rel='noreferrer'>
                <div className='linkedIn-img-wrapper'>
                  <img src={LinkedIn} alt='LinkedIn Img' className='contact-img' />
                </div>
                {windowWidth > 329 ? <h2 className='contact-text'>LinkedIn</h2> : null}
              </a>
            </div>
            <div className='contact-wrapper'>
              <a href='mailto: celiawong0119@gmail.com'>
                <div className='email-img-wrapper'>
                  <img src={Email} alt='Email Img' className='contact-img' />
                </div>
                {windowWidth > 329 ? <h2 className='contact-text'>Email</h2> : null}
              </a>
            </div>
            <div className='contact-wrapper'>
              <a href='https://api.whatsapp.com/send?phone=85269918990' target='_blank' rel='noreferrer'>
                <div className='whatsapp-img-wrapper'>
                  <img src={Whatsapp} alt='Whatsapp Img' className='contact-img' />
                </div>
                {windowWidth > 329 ? <h2 className='contact-text'>Whatsapp</h2> : null}
              </a>
            </div>
          </div>
          <div className='back-top-button-border jump'>
            <a id='TooltipBackTop' className='back-top-button' onClick={() => scroll()}>
              <img src={AirBallon} alt='back top' className='back-top-img' />
            </a>
            <Tooltip placement='bottom' isOpen={tooltipOpen} target='TooltipBackTop' toggle={toggle}>
              Back to top
            </Tooltip>
          </div>
        </div>
        {windowWidth > 854 ? (
          <div>
            <div className='footer-cloud1-img-wrapper-initial'>
              <img src={Cloud1} className='footer-large-cloud-img' alt='Cloud Img' />
            </div>
            <div className='footer-cloud1-img-wrapper'>
              <img src={Cloud1} className='footer-large-cloud-img' alt='Cloud Img' />
            </div>
            <div className='footer-cloud2-img-wrapper-initial'>
              <img src={Cloud2} className='footer-large-cloud-img' alt='Cloud Img' />
            </div>
            <div className='footer-cloud2-img-wrapper'>
              <img src={Cloud2} className='footer-large-cloud-img' alt='Cloud Img' />
            </div>
            <div className='footer-cloud3-img-wrapper-initial'>
              <img src={Cloud1} className='footer-large-cloud-img' alt='Cloud Img' />
            </div>
            <div className='footer-cloud3-img-wrapper'>
              <img src={Cloud1} className='footer-large-cloud-img' alt='Cloud Img' />
            </div>
            <div className='footer-cloud4-img-wrapper-initial'>
              <img src={Cloud3} className='footer-medium-cloud-img' alt='Cloud Img' />
            </div>
            <div className='footer-cloud4-img-wrapper'>
              <img src={Cloud3} className='footer-medium-cloud-img' alt='Cloud Img' />
            </div>
            <div className='footer-cloud5-img-wrapper-initial'>
              <img src={Cloud3} className='footer-medium-cloud-img' alt='Cloud Img' />
            </div>
            <div className='footer-cloud5-img-wrapper'>
              <img src={Cloud3} className='footer-medium-cloud-img' alt='Cloud Img' />
            </div>
            <div className='footer-cloud6-img-wrapper-initial'>
              <img src={Cloud3} className='footer-small-cloud-img' alt='Cloud Img' />
            </div>
            <div className='footer-cloud6-img-wrapper'>
              <img src={Cloud3} className='footer-small-cloud-img' alt='Cloud Img' />
            </div>
            <div className='footer-cloud7-img-wrapper-initial'>
              <img src={Cloud4} className='footer-cloud-group-img' alt='Cloud Img' />
            </div>
            <div className='footer-cloud7-img-wrapper'>
              <img src={Cloud4} className='footer-cloud-group-img' alt='Cloud Img' />
            </div>
            <div className='footer-cloud8-img-wrapper-initial'>
              <img src={Cloud4} className='footer-cloud-group-img' alt='Cloud Img' />
            </div>
            <div className='footer-cloud8-img-wrapper'>
              <img src={Cloud4} className='footer-cloud-group-img' alt='Cloud Img' />
            </div>
          </div>
        ) : (
          <div>
            <div className='footer-cloud1-img-wrapper-initial'>
              <img src={Cloud1} className='footer-large-cloud-img' alt='Cloud Img' />
            </div>
            <div className='footer-cloud1-img-wrapper'>
              <img src={Cloud1} className='footer-large-cloud-img' alt='Cloud Img' />
            </div>
            <div className='footer-cloud2-img-wrapper-initial'>
              <img src={Cloud2} className='footer-large-cloud-img' alt='Cloud Img' />
            </div>
            <div className='footer-cloud2-img-wrapper'>
              <img src={Cloud2} className='footer-large-cloud-img' alt='Cloud Img' />
            </div>
            <div className='footer-cloud4-img-wrapper-initial'>
              <img src={Cloud3} className='footer-medium-cloud-img' alt='Cloud Img' />
            </div>
            <div className='footer-cloud4-img-wrapper'>
              <img src={Cloud3} className='footer-medium-cloud-img' alt='Cloud Img' />
            </div>
            <div className='footer-cloud6-img-wrapper-initial'>
              <img src={Cloud3} className='footer-small-cloud-img' alt='Cloud Img' />
            </div>
            <div className='footer-cloud6-img-wrapper'>
              <img src={Cloud3} className='footer-small-cloud-img' alt='Cloud Img' />
            </div>
            <div className='footer-cloud7-img-wrapper-initial'>
              <img src={Cloud4} className='footer-cloud-group-img' alt='Cloud Img' />
            </div>
            <div className='footer-cloud7-img-wrapper'>
              <img src={Cloud4} className='footer-cloud-group-img' alt='Cloud Img' />
            </div>
            <div className='footer-cloud8-img-wrapper-initial'>
              <img src={Cloud4} className='footer-cloud-group-img' alt='Cloud Img' />
            </div>
            <div className='footer-cloud8-img-wrapper'>
              <img src={Cloud4} className='footer-cloud-group-img' alt='Cloud Img' />
            </div>
          </div>
        )}
      </div>
      <img src={BackgroundBottom} alt='Background Bottom' className='footer-background-bottom' />
    </Element>
  );
};

export default Footer;
