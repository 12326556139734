import Scroll from 'react-scroll';
import BackgroundBottom from '../../assets/SkillsBackgroundBottom.png';
import BackgroundBottom2 from '../../assets/SkillsBackgroundBottom2.png';
import SkillsGirl from '../../assets/SkillsGirl.png';
import SkillsStar from '../../assets/SkillsStar.png';
import SkillsStar2 from '../../assets/SkillsStar2.png';
import SkillsStar3 from '../../assets/SkillsStar3.png';
import './styles.scss';

const Element = Scroll.Element;

interface props {
  windowWidth: number;
}

const Skills = ({ windowWidth }: props) => {
  return (
    <Element name='skills'>
      <div className='skills-section-wrapper'>
        <div className='skills-section'>
          <div className='skills-content'>
            <h2 className='skills-title'>Skills</h2>
            <p className='skills-text'>
              HTML, CSS, JavaScript, Typescript, ReactJS and React Native <br />
              <br />
              <span>Hands on experiences on Java Spring Boot, NodeJS and AWS</span>
            </p>
            <p className='skills-text skills-text2-margin'>
              Previous working knowledge: Python, SQL queries, Visual Basic and Tableau
            </p>
            <div style={{ display: 'flex' }}>
              <div className='skills-button-wrapper'>
                <div className='skills-button-border'>
                  <a
                    href='https://instapic.celiawongwsy.com/'
                    className='skills-button'
                    target='_blank'
                    rel='noreferrer'
                  >
                    InstaPic
                  </a>
                </div>
              </div>
              <div style={{ width: 20 }} />
              <div className='skills-button-wrapper'>
                <div className='skills-button-border'>
                  <a
                    href='https://whatsapp-clone.celiawongwsy.com/'
                    className='skills-button'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Whatsapp Clone
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className='skills-girl-img-wrapper'>
            <img src={SkillsGirl} alt='Skills Img' className='skills-girl-img' />
          </div>
          {windowWidth > 767 ? (
            <div className='skills-star-img-wrapper'>
              <img src={SkillsStar} className='skills-star-img' alt='Star Img' />
            </div>
          ) : (
            <div>
              <div className='skills-star-img-wrapper'>
                <img src={SkillsStar2} className='skills-star-img' alt='Star Img' />
              </div>
              <div className='skills-star3-img-wrapper'>
                <img src={SkillsStar3} className='skills-star3-img' alt='Star Img' />
              </div>
            </div>
          )}
        </div>
      </div>
      {windowWidth > 767 ? (
        <img src={BackgroundBottom} className='skills-background-bottom' alt='Bottom Img' />
      ) : (
        <img src={BackgroundBottom2} className='skills-background-bottom' alt='Bottom Img' />
      )}
    </Element>
  );
};

export default Skills;
